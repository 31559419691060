require('./bootstrap');

//button scroll-up
let buttonUp = document.getElementById('buttonUp')

window.onscroll = function () {
    scrollFunction()
}

function scrollFunction() {
    if (document.body.scrollTop > 600 || document.documentElement.scrollTop > 600) {
        buttonUp.style.display = 'block'
    } else {
        buttonUp.style.display = 'none'
    }
}

buttonUp.addEventListener('click', () => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
})

//img-scale

let thumb = document.getElementsByClassName('thumb')[0]

thumb.addEventListener('mouseover', () => {
    thumb.classList.add('product-img-thumb')
})

thumb.addEventListener('mouseleave', () => {
    thumb.classList.remove('product-img-thumb')
})

thumb.addEventListener('click', () => {
    thumb.classList.toggle('product-img-thumb')
})




